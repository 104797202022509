import React from "react";
import "./ProgressIcon.scss";

export default function ProgressIcon() {
  return (
    <svg class="progress-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path fill="none" stroke-linecap="round" stroke-width="4" stroke="currentColor" d="M44,24c0,11.05-8.95,20-20,20S4,35.05,4,24,12.95,4,24,4"/>
    </svg>
  );
}
