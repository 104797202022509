import React, { useRef, useEffect }  from "react";
import { useLocation } from "wouter";
import "./Panel.scss";
import Footer from "./Footer";

export default function Panel({ name, id, className, children, noWrapper, addFooter }) {
  const panelRef = useRef();

  const [location, setLocation] = useLocation();

  useEffect(() => {
    const getId = (str) => {
      const result = str.replace(/[/#]/g, "");
      return result;
    };

    const onScrollEnd = () => {
      const panelEl = panelRef.current;
      const isStartPanel = panelEl.offsetTop === 0;
      const isPanelHash = getId(window.location.hash) === !isStartPanel ? id : "";

      const panelRect = panelEl.getBoundingClientRect();
      const isTopInView = panelRect.top <= window.innerHeight/2 && panelRect.top >= 0;
      const isBottomInView = panelRect.bottom >= window.innerHeight/2 && panelRect.bottom <= window.innerHeight;
      const isInView = isTopInView || isBottomInView;
      //const isInView = panelEl.offsetTop / window.innerHeight === Math.floor(window.scrollY / window.innerHeight);
      if(!isPanelHash && isInView){
        setLocation(!isStartPanel ? `/#${id}` : "/",{replace:true});
      }
    };
    window.addEventListener("scrollend", onScrollEnd);
    return () => {
      window.removeEventListener("scrollend", onScrollEnd);
    };
  }, []);

  return (
    <div ref={panelRef} id={id} className={`panel ${className ? className : ''}`}>
      {!noWrapper ? (
      <div className="wrapper">
        {name && (
          <div className="panel-title">
            <h1>{name}</h1>
          </div>
        )}
        {children}
      </div>) : children}
      {addFooter && (<Footer />)}
    </div>
  );
}