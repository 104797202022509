import React from "react";
import "./ProjectsPanel.scss";
import Panel from "../../Layout/Panel";
import { ReactComponent as VisitIcon } from "../../../Assets/Icons/ArrowRight.svg";

import {LandscapeM} from "../../../PlaceholderImages";

export default function ProjectsPanel() {
  return (
    <Panel name="projekte" id="projekte" className="projects">
      <ul className="selection">
        
        <li>
          <div className="card">
            <a href="/projekte/projekt"><img src={LandscapeM} alt="" /></a>
            <div className="info">
              <h2>Projektname</h2>
              <p>Agentur / Aufragtsgeber / Zeitraum</p>
              <a href="/projekte/projekt">
                <div><span>Besuchen</span><VisitIcon/></div>
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className="card">
            <a href="/projekte/projekt"><img src={LandscapeM} alt="" /></a>
            <div className="info">
              <h2>Projektname</h2>
              <p>Agentur / Aufragtsgeber / Zeitraum</p>
              <a href="/projekte/projekt">
                <div><span>Besuchen</span><VisitIcon/></div>
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className="card">
            <a href="/projekte/projekt"><img src={LandscapeM} alt="" /></a>
            <div className="info">
              <h2>Projektname</h2>
              <p>Agentur / Aufragtsgeber / Zeitraum</p>
              <a href="/projekte/projekt">
                <div><span>Besuchen</span><VisitIcon/></div>
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className="card">
            <a href="/projekte/projekt"><img src={LandscapeM} alt="" /></a>
            <div className="info">
              <h2>Projektname</h2>
              <p>Agentur / Aufragtsgeber / Zeitraum</p>
              <a href="/projekte/projekt">
                <div><span>Besuchen</span><VisitIcon/></div>
              </a>
            </div>
          </div>
        </li>
        
      </ul>
    </Panel>
  );
}