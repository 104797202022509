import React from "react";
import "./Section.scss";

export default function Section({ className, children }) {
  return (
    <div className={`section ${className ? className : ''}`}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
}