import React from "react";
import "./Footer.scss";

export default function Footer({ children }) {
  return (
    <ul className="footer">
      <li>
        <span>© Arne Kossmann - Portfolio 2024</span>
      </li>
      <li>
        <a href="/impressum">Impressum</a><a href="/datenschutz">Datenschutz</a>
      </li>
    </ul>
  );
}