import React from "react";
import "./ScrollDownIcon.scss";

export default function ScrollDownIcon() {
  return (
    <svg className="scroll-down-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <polyline fill="none" strokeWidth="4" stroke="currentColor" points="45 23.5 24 44.5 3 23.5"/>
      <line fill="none" strokeWidth="4" stroke="currentColor" x1="24" y1="44.5" x2="24" y2="3.5"/>
    </svg>
  );
}
