import React from "react";
import Page from "../../Layout/Page";
import Section from "../../Layout/Section";
import Gallery from "../../Layout/Gallery";

import {LandscapeXL, LandscapeM} from "../../../PlaceholderImages";

export default function ProjectPage() {

  const gallerySlides = [
    {
      thumb: LandscapeM,
      src: LandscapeXL,
      title: "Titel",
      description: "Beschreibung",
    },
    {
      thumb: LandscapeM,
      src: LandscapeXL,
      title: "Titel",
      description: "Beschreibung",
    },
    {
      thumb: LandscapeM,
      src: LandscapeXL,
      title: "Titel",
      description: "Beschreibung",
    },
  ];

  return (
    <Page>
      <Section>
        <div className="hero">
          <div>
            <h1>Projektname</h1>
            <h4>Agentur / Aufragtsgeber / Zeitraum</h4>
          </div>
          <div>
            <img src={LandscapeXL} alt=""/>
          </div>
          <div>
            <p>Kurzbeschreibung</p>
          </div>
        </div>
        <Gallery
          slides={gallerySlides}
        />
      </Section>
    </Page>
  );
}