import React, { useState, useEffect } from "react";
import "./NavBar.scss";
import { ReactComponent as Logo } from "../../Assets/Images/ak-logo.svg";
import NavButton from "../UI/NavButton";
import NavMenuButton from "../UI/NavMenuButton";

export default function NavBar() {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  }

  const onClose = () => {
    setActive(false);
  };

  const onResize = () => {
    const isDesktop = matchMedia("(min-width: 768px)").matches;
    if(isDesktop) setActive(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <header className={`nav-header${active ? " nav-active" : ""}`}>
      <div className="nav-logo">
        <NavButton className="nav-logo-button" route="/" onClick={onClose}>
          <Logo />
        </NavButton>
        <NavMenuButton isActive={active} onClick={toggleActive} />
      </div>
      <nav className="nav-menu">
        <div className="nav-menu-item">
          <NavButton route="/#ich" onClick={onClose}>Ich</NavButton>
        </div>
        <div className="nav-menu-item">
          <NavButton route="/#projekte" onClick={onClose}>Projekte</NavButton>
        </div>
        <div className="nav-menu-item">
          <NavButton route="/#kontakt" onClick={onClose}>Kontakt</NavButton>
        </div>  
      </nav>
    </header>
  );
}
