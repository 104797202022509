import React from 'react';
import { Switch, Route, Redirect} from "wouter";
import HomePage from "./Components/Pages/Home/HomePage";

import ImprintPage from './Components/Pages/Common/ImprintPage';
import PrivacyPolicyPage from './Components/Pages/Common/PrivacyPolicyPage';

import ProjectPage from './Components/Pages/Projects/ProjectPage';

function App() {
  return (
    <Switch>
      <Route path="/">
        <HomePage />
      </Route>

      {/* Project Pages */}
      <Route path="/projekte/projekt">
        <ProjectPage />
      </Route>

      {/* Common Pages */}
      <Route path="/impressum">
        <ImprintPage />
      </Route>

      <Route path="/datenschutz">
        <PrivacyPolicyPage />
      </Route>

      {/* Default route in a switch */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
    
  );
}

export default App;
