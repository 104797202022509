import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "./Gallery.scss";

export default function Gallery({slides}) {
  const [slideIndex, setSlideIndex] = useState(-1);

  const handleOnClick = (event, index) => {
    event.stopPropagation();
    setSlideIndex(index);
  }

  const galleryItems = slides.map((slide, index) =>
    <li key={index}>
      <button onClick={(event) => handleOnClick(event, index)}>
        <img src={slide.thumb} alt=""/>
      </button>
      <p>{`${slide.title} | ${slide.description}`}</p>
    </li>
  );

  return (
    <>
      <ul className="gallery">
        {galleryItems}
      </ul>
      
      <Lightbox
        plugins={[Captions]}
        index={slideIndex}
        slides={slides}
        open={slideIndex >= 0}
        close={() => setSlideIndex(-1)}
      />
    </>
  );
}
