import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

export default function Page({ children }) {
  return (
    <div className="page">
      <NavBar />
      {children}
      <Footer />
    </div>
  );
}