import React from "react";
import OnePage from "../../Layout/OnePage";
import HomePanel from "./HomePanel";
import AboutPanel from "./AboutPanel";
import ProjectsPanel from "./ProjectsPanel";
import ContactPanel from "./ContactPanel";

export default function HomePage() {
  return (
    <OnePage>
      <HomePanel />
      <AboutPanel />
      <ProjectsPanel />
      <ContactPanel />
    </OnePage>
  );
}