import React from "react";
import "./HomePanel.scss";
import Panel from "../../Layout/Panel";
import ScrollDownIcon from "../../UI/ScrollDownIcon";

import {LandscapeXL} from "../../../PlaceholderImages";

export default function HomePanel({ children }) {
  return (
    <Panel id="home" className="home" noWrapper={true}>
      <div className="hero">
        <img className="background" src={LandscapeXL} alt="" />
        <h1>Arne Kossmann</h1>
        <h2>Portfolio 2024</h2>
        <div className="icon"><ScrollDownIcon/></div>
      </div>
    </Panel>
  );
}